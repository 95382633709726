<template>
  <div>
    <vs-row vs-type="flex" class="mt-0 mb-4" vs-justify="center" vs-align="center" vs-w="12">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          size="small"
          label="Data De"
          v-model="filtro.dataDe"
        ></vs-input>
        <vs-input
          color="dark"
          class="mx-1"
          type="date"
          label="Data Até"
          size="small"
          v-model="filtro.dataAte"
        ></vs-input>
        <div>
          <label style="color: black; font-size: 0.85rem" class="pl-2">Tipo da Data</label>
          <br />
          <el-select
            filterable
            size="small"
            clearable
            placeholder="Selecione"
            v-model="filtro.tipoData"
            class="mx-1"
          >
            <el-option v-for="data in tipoDatas" :value="data.id" :label="data.nome" :key="data.id"></el-option>
          </el-select>
        </div>
        <vs-input
          color="dark"
          class="mx-1"
          size="small"
          type="text"
          placeholder
          label="Busca"
          v-model="filtro.busca"
        ></vs-input>
        <div>
          <label style="color: black; font-size: 0.85rem" class="pl-2">Colaborador</label>
          <br />
          <el-select
            filterable
            clearable
            remote
            size="small"
            :remote-method="filtroColaborador"
            placeholder="Pesquise"
            loading-text="Buscando..."
            :loading="loading"
            v-model="filtro.colaborador"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="colaborador in colaboradores"
              :value="colaborador.id"
              :label="colaborador.nome"
              :key="colaborador.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="pl-2">Tipo do Assunto</label>
          <br />
          <el-select
            filterable
            size="small"
            clearable
            placeholder="Selecione"
            v-model="filtro.tipoAssunto"
            class="mx-1"
          >
            <el-option
              v-for="assunto in tipoAssuntos"
              :value="assunto.id"
              :label="assunto.nome"
              :key="assunto.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="pl-2">Estagio</label>
          <br />
          <el-select
            size="small"
            filterable
            clearable
            placeholder="Selecione"
            v-model="filtro.estagio"
            class="mx-1"
          >
            <el-option
              v-for="estagio in estagios"
              :value="estagio.id"
              :label="estagio.nome"
              :key="estagio.id"
            ></el-option>
          </el-select>
        </div>
        <div>
          <label style="color: black; font-size: 0.85rem" class="pl-2">Status</label>
          <br />
          <el-select
            size="small"
            filterable
            clearable
            placeholder="Selecione"
            v-model="filtro.status"
            class="mx-1"
          >
            <el-option
              v-for="status in statuses"
              :value="status.value"
              :label="status.nome"
              :key="status.id"
            ></el-option>
          </el-select>
        </div>
        <el-button size="small" type="primary" class="mx-1" plain @click="getAssuntos()">FILTRAR</el-button>
      </vs-col>
    </vs-row>
    <div id="assunto" class="cardTable mb-1 vs-con-loading__container">
      <div v-if="assuntos.length == 0">
        <div class="pt-20 scroll-assunto con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">Nenhum Assunto</h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="mt-1">
        <VuePerfectScrollbar class="scroll-assunto" :settings="settings">
          <vs-table maxHeight="63vh" stripe max-items="10" pagination :data="assuntos">
            <template slot="thead">
              <vs-th sort-key="id_contrato" style="width: 5%" id="center">Contrato</vs-th>
              <vs-th sort-key="id_colaborador" style="width: 15%">Cliente</vs-th>
              <vs-th sort-key="id_colaborador" style="width: 15%">Colaborador</vs-th>
              <vs-th sort-key="id_assuntos" style="width: 15%">Assunto</vs-th>
              <vs-th style="width: 15%" id="center">Status</vs-th>
              <vs-th style="width: 5%" id="center">Cadastro</vs-th>
              <vs-th style="width: 5%" id="center">Lembrete</vs-th>
              <vs-th style="width: 25%" id="center">Observação</vs-th>
              <vs-th style="width: 5%" id="center">Respostas</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="index" v-for="(tr, index) in data">
                <vs-td :data="tr.id_contrato" class="text-center">
                  <a
                    v-if="tr.id_contrato > 0"
                    :href="'contrato?id=' + tr.id_contrato"
                    style="font-size: 12px; color: darkblue; cursor: pointer"
                  >
                    <b>{{ tr.id_contrato > 0 ? tr.id_contrato : "..." }}</b>
                  </a>
                </vs-td>
                <vs-td
                  :data="tr.nomeCliente"
                  class="font-semibold"
                  style="font-size: 12px"
                >{{ tr.nomeCliente }}</vs-td>
                <vs-td
                  :data="tr.id_colaborador"
                  class="font-semibold"
                  style="font-size: 12px"
                >{{ tr.colaborador }}</vs-td>
                <vs-td :data="tr.id_assuntos" style="font-size: 12px">
                  {{
                  tr.assunto
                  }}
                </vs-td>
                <vs-td class="text-center">
                  <div class="w-full" @click="changeStatus(tr)" style="cursor: pointer">
                    <vs-row vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                      <vs-col vs-w="12" vs-type="flex" vs-justify="center" vs-align="center">
                        <Badge
                          :key="reRenderBadge"
                          :color="
                            tr.status == 0 || tr.status == '0'
                              ? '#0044f2'
                              : 'success'
                          "
                          :text="
                            tr.status == 0 || tr.status == '0'
                              ? 'Em processo'
                              : 'Concluido'
                          "
                        ></Badge>
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-td>
                <vs-td class="text-center">{{ tr.data_criacao }}</vs-td>
                <vs-td class="text-center">{{ tr.data_marcada }}</vs-td>
                <vs-td style="font-size: 12px">{{ tr.obs }}</vs-td>
                <vs-td style="font-size: 12px">
                  <div class="w-full">
                    <vs-row vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
                      <vs-row vs-w="12" vs-type="flex" vs-align="center" vs-justify="center">
                        <vs-button
                          @click="openModalRespostas(tr)"
                          icon="question_answer"
                          size="small"
                          color="success"
                          type="relief"
                          radius
                        />
                      </vs-row>
                    </vs-row>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </VuePerfectScrollbar>
        <vx-card class="mt-4" no-shadow card-background="dark">
          <vs-row vs-type="flex" vs-justify="flex-start" vs-align="center">
            <vs-col vs-w="6" vs-type="flex" vs-justify="flex-start" vs-align="center">
              <span style="color: white; font-size: 14px" class="ml-2">Total: {{ assuntos.length }}</span>
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
    </div>
    <component v-bind:is="modalRespostas" :key="reRenderModalRespostas" :assunto="selectedAssunto" />
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

import modalRespostas from "./../contrato/components/modalRespostas";
export default {
  data() {
    return {
      modalRespostas: "",
      reRenderModalRespostas: 0,
      selectedAssunto: {},
      assuntos: [],
      reRenderBadge: 1,
      colaboradores: [],
      tipoAssuntos: [],
      tipoDatas: [
        { id: 1, nome: "Criacao" },
        { id: 2, nome: "Lembrete" }
      ],

      estagios: [],
      statuses: [
        { id: 1, nome: "Em Processo", value: 0 },
        { id: 2, nome: "Concluído", value: 1 }
      ],
      loading: false,
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
        tipoData: 1
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      reRender: 0
    };
  },
  methods: {
    async getAssuntos() {
      await this.$vs.loading({ container: "#assunto", scale: 0.6 });
      try {
        this.assuntos = await this.$http.post(`getAssuntos`, this.filtro);
        this.reRenderBadge += 1;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#assunto > .con-vs-loading");
      }
    },
    async getTipoAssuntos() {
      try {
        this.tipoAssuntos = await this.$http.get(`listar-assuntos`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getColaboradoresByQuery(query) {
      try {
        this.colaboradores = await this.$http.post(`getColaboradorByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroColaborador(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getColaboradoresByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async getEstagios() {
      try {
        this.estagios = await this.$http.get(`contrato_estagio`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async changeStatus(doc) {
      await this.$vs.loading({ container: "#assunto", scale: 0.6 });
      try {
        await this.$http.post("markLembreteAsRead", {
          id: doc.id,
          status: !doc.status
        });
        await this.getAssuntos();
        this.reRenderBadge += 1;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#assunto > .con-vs-loading");
      }
    },
    async openModalRespostas(assunto) {
      this.selectedAssunto = assunto;
      this.modalRespostas = "modalRespostas";
      this.reRenderModalRespostas += 1;
    }
  },
  async mounted() {
    await this.getAssuntos();
    await this.getTipoAssuntos();
    await this.getEstagios();
  },
  components: { ...components, modalRespostas }
};
</script>
<style lang="scss">
.scroll-assunto {
  height: 72vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
</style>
